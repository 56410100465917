import React, { useState, useEffect, useMemo, useCallback } from "react";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useSession } from "next-auth/react";

import { generators, editors, assistants, agents, prompts } from "@/data/aitools"
import SmallNavItem from "../../data/header.json";

const SmallNav = () => {
  const router = useRouter();
  const { data: session, status } = useSession();

  const [tools, setTools] = useState([]);

  const isActive = (href) => router.pathname === href;

  useEffect(() => {
    // combine all tools into one array. Exclude the ones with badge "coming"
    const allTools = [...generators, ...editors, ...assistants, ...agents].filter(tool => tool.badge !== "coming");
    setTools(allTools);
  }, []);

  return (
    <>
      <nav className="mainmenu-nav">
        {session?.user && (
          <ul className="dashboard-mainmenu rbt-default-sidebar-list">
            <li>
              <Link href="/dashboard">
                <i className="feather-monitor"></i>
                <span>Welcome</span>
              </Link>
            </li>
            <li>
              <Link href="/dashboard/settings/plans-billing">
                <i className="feather-briefcase"></i>
                <span>Manage Subsription</span>
              </Link>
            </li>
          </ul>
        )}

        <div className="rbt-sm-separator"></div>
        <ul className="dashboard-mainmenu rbt-default-sidebar-list">
          {tools && tools
            .sort(() => Math.random() - Math.random())
            .slice(0, 20)
            .map((data, index) => (
              <li key={index}>
                <Link
                  // className={data.isDisable ? "disabled" : ""}
                  className={
                    isActive(data.url)
                      ? "active"
                      : "" || data.isDisable
                        ? "disabled"
                        : ""
                  }
                  href={data.url}
                >
                  <Image
                    src={data.icon}
                    width={35}
                    height={35}
                    alt="AI Generator"
                  />
                  <span>{data.name}</span>
                  {data.badge !== "" ? (
                    <div className="rainbow-badge-card badge-sm ml--10">
                      {data.badge}
                    </div>
                  ) : (
                    ""
                  )}
                </Link>
              </li>
            ))}
        </ul>

        {session?.user && (
          <>
            <div className="rbt-sm-separator"></div>
            
            <div className="mainmenu-nav">
              <ul className="dashboard-mainmenu rbt-default-sidebar-list">
                <li className="has-submenu">
                  <a
                    className="collapse-btn collapsed"
                    data-bs-toggle="collapse"
                    href="#collapseExampleMenu"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExampleMenu"
                  >
                    <i className="feather-plus-circle"></i>
                    <span>Setting</span>
                  </a>
                  <div className="collapse" id="collapseExampleMenu">
                    <ul className="submenu rbt-default-sidebar-list">
                      {SmallNavItem &&
                        SmallNavItem.smallNavItem
                          .slice(7, 14)
                          .map((data, index) => (
                            <li key={index}>
                              <Link href={data.link}>
                                <i className={`feather-${data.icon}`}></i>
                                <span>{data.text}</span>
                              </Link>
                            </li>
                          ))}
                    </ul>
                  </div>
                </li>
                <li>
                  <a href="#">
                    <i className="feather-award"></i>
                    <span>Help & FAQ</span>
                  </a>
                </li>
              </ul>

              <div className="rbt-sm-separator"></div>
              <ul className="dashboard-mainmenu rbt-default-sidebar-list">
                <li>
                  <Link href="/dashboard/settings/release-notes">
                    <i className="feather-bell"></i>
                    <span>Release notes</span>
                  </Link>
                </li>
                <li>
                  <Link href="/privacy-policy">
                    <i className="feather-briefcase"></i>
                    <span>Terms & Policy</span>
                  </Link>
                </li>
              </ul>
            </div>
          </>
        )}
      </nav>
    </>
  );
};

export default SmallNav;

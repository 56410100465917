import Image from "next/image";
import Link from "next/link";

import avatar from "../../public/images/team/team-01.jpg";
import UserMenuItems from "./HeaderProps/UserMenuItems";

import { useSession, signIn, signOut } from "next-auth/react";
import { Coins } from "lucide-react";

const UserMenu = () => {
  const { data: session } = useSession();

  const handleSignIn = () => {
    signIn();
  };

  const handleSignOut = () => {
    signOut();
  };

  return (
    <>
      <div className="inner">
        <div className="rbt-admin-profile">
          <div className="admin-thumbnail">
            <Image src={session?.user?.avatar} alt={session?.user?.full_name} width={128} height={128} />
          </div>

          <div className="admin-info">
            <span className="name">{session?.user?.full_name}</span>
            <Link
              className="rbt-btn-link color-primary"
              href="/profile-details"
            >
              View Profile
            </Link>
          </div>
        </div>

        <div className="cre8dit-info">
          <div className="content">
            {session?.user?.is_subscribed ? (
              <p className="status">You are currently on {session?.user?.subscription?.plan_name}</p>
            ) : (
              <p className="status">You are currently on Free plan</p>
            )}

            <div className="credit-bar">
              <div className="credit-info">
                {/* <svg className="icon" xmlns="http://www.w3.org/2000/svg" color="orange">
                  <path d="M6.92851 0.727609C6.92859 0.567738 6.87693 0.412296 6.78152 0.285327C6.6861 0.158358 6.55226 0.0669382 6.40069 0.0252099C6.24911 -0.0165185 6.08826 -0.00623016 5.943 0.0544834C5.79775 0.115197 5.6762 0.222952 5.59714 0.361081L0.59735 9.08795C0.53401 9.19847 0.500431 9.32405 0.500004 9.45201C0.499577 9.57997 0.532318 9.70578 0.59492 9.81673C0.657523 9.92769 0.74777 10.0199 0.85655 10.0839C0.96533 10.148 1.08879 10.1818 1.21447 10.1817H4.07149V15.2724C4.07141 15.4323 4.12307 15.5877 4.21848 15.7147C4.3139 15.8416 4.44774 15.9331 4.59931 15.9748C4.75089 16.0165 4.91174 16.0062 5.057 15.9455C5.20225 15.8848 5.32381 15.777 5.40286 15.6389L10.4027 6.91205C10.466 6.80153 10.4996 6.67595 10.5 6.54799C10.5004 6.42003 10.4677 6.29422 10.4051 6.18327C10.3425 6.07231 10.2522 5.98014 10.1434 5.91605C10.0347 5.85197 9.91121 5.81824 9.78553 5.81828H6.92851V0.727609Z" fill="#FF5D00"></path>
                </svg> */}
                <Coins size={20} />
                <p className="credit-amount text-light">{session?.user?.cre8dits}</p>
              </div>
              <div className="credit-label">
                <p className="label-text">Cre8dits</p>
              </div>
            </div>
          </div>
        </div>

        <UserMenuItems parentClass="user-list-wrapper user-nav" />

        <hr className="mt--10 mb--10" />

        <ul className="user-list-wrapper user-nav">
          <li>
            <Link href="#">
              <i className="feather-help-circle"></i>
              <span>Help Center</span>
            </Link>
          </li>
          <li>
            <Link href="/dashboard/settings/profile-details">
              <i className="feather-settings"></i>
              <span>Settings</span>
            </Link>
          </li>
        </ul>
        <hr className="mt--10 mb--10" />
        <ul className="user-list-wrapper">
          <li>
            <Link href="/auth/signout">
              <i className="feather-log-out"></i>
              <span>Logout</span>
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default UserMenu;

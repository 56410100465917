import React, { useEffect } from "react";
import { useRouter } from "next/router";
import { useSession } from "next-auth/react";
import { useAppContext } from "@/context/Context";

import Image from "next/image";
import Link from "next/link";

import DashboardItem from "@/data/header.json";

import menuImg from "@/public/images/menu-img/menu-img-2.webp";

const Nav = ({activeMobileMenu = false}) => {
  const { data: session } = useSession();
  const router = useRouter();

  const { showItem, setShowItem } = useAppContext();
  
  const isActive = (href) => router.pathname === href;

  return (
    <>
      <ul className="mainmenu">
        <li>
          <Link href="/">Home</Link>
        </li>

        {session?.user && (
          <li>
            <Link href="/dashboard">Dashboard</Link>
          </li>
        )}

        <li className="with-megamenu has-menu-child-item position-relative">
          <a
            href="javascript:void(0)"
            onClick={() => setShowItem(!showItem)}
            className={`${!showItem ? "open" : ""}`}
          >
            Tools
          </a>
          <div
            className={`rainbow-megamenu with-mega-item-2 ${showItem ? "" : "d-block"
              }`}
          >
            <div className="wrapper p-0">
              <div className="row row--0">
                <div className="col-lg-6 single-mega-item">
                  <h3 className="rbt-short-title">{activeMobileMenu ? "Discover AI Tools" : "Browse AI Tools"}</h3>
                  <ul className="mega-menu-item">
                    <li>
                      <Link
                        href="/tools"
                        className={isActive("/tools") ? "active" : ""}
                      >
                        All
                      </Link>
                    </li>

                    {DashboardItem && DashboardItem.aiTools.map((data, index) => (
                      <li key={index}>
                        <Link
                          href={data.link}
                          className={isActive(data.link) ? "active" : ""}
                        >
                          {data.text}
                        </Link>
                      </li>
                    ))}
                  </ul>
                  {/* <div className="header-menu-img">
                    <Image src={menuImg} alt="Menu Split Image" />
                  </div> */}
                </div>

                <div className="col-lg-6 single-mega-item mt-2 mt-lg-0">
                  <h3 className="rbt-short-title">{activeMobileMenu ? "Discover Basic Tools" : "Browse Basic Tools"}</h3>
                  <ul className="mega-menu-item">
                    <li>
                      <Link
                        href="/basic-tools"
                        className={isActive("/basic-tools") ? "active" : ""}
                      >
                        All
                      </Link>
                    </li>

                    {DashboardItem && DashboardItem.basicTools.map((data, index) => (
                      <li key={index}>
                        <Link
                          href={data.link}
                          className={isActive(data.link) ? "active" : ""}
                        >
                          {data.text}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </li>

        <li>
          <Link href="/blog">Blog</Link>
        </li>

        <li>
          <Link href="/use-cases">Use Cases</Link>
        </li>

        <li>
          <Link href="/pricing">Pricing</Link>
        </li>

        {/* <li>
          <Link href="/about">About</Link>
        </li>
        
        <li>
          <Link href="/contact">Contact</Link>
        </li> */}

        {!session?.user && (
          <li>
            <Link href="/auth/signin">Sign In</Link>
          </li>
        )}
      </ul>
    </>
  );
};

export default Nav;

import Head from "next/head";
import Script from "next/script";

import { useRouter } from 'next/router';

const PageHead = ({ title }) => {
  const router = useRouter();
  const canonicalUrl = `https://cre8tegpt.com${router.asPath}`;

  return (
    <>
      <Head>
        <title>{(title ? title + " | " : "") + "Cre8teGPT"}</title>

        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="description" content="Cre8teGPT offers premium AI tools for content creators, including AI chat, image and video generators, article and lyrics generators, and various content assistants and agents to enhance your creative workflow." />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        
        <meta name="keywords" content="AI tools, content creation, AI chatbot, image generator, article generator, lyrics generator, ad copy generator, marketing email generator, recipe generator, text to speech, speech to text, YouTube summarizer, YouTube channel analyzer, YouTube thumbnail generator, code generator, website generator, XML sitemap generator, logo generator, video generator, photo editor, video editor, writing assistant, YouTube video assistant, YouTube script assistant, research assistant, content moderator agent, customer support agent, SEO agent, social media agent, market research agent, content creation agent, engagement agent, analytics agent, advertising agent, collaboration agent, personal assistant agent, feedback agent, monetization agent, Cre8teGPT" />
        <meta name="author" content="Cre8teGPT" />

        {/* <meta property="og:title" content={title + " | Hundreds of AI Tools in One Platform"} />
        <meta property="og:description" content="Cre8teGPT offers premium AI tools for content creators, including AI chat, image and video generators, article and lyrics generators, and various content assistants and agents to enhance your creative workflow." />
        <meta property="og:image" content="/images/logo/logo-big.png" />
        <meta property="og:url" content="https://cre8tegpt.com" />
        <meta property="og:type" content="website" /> */}

        <meta property="og:site_name" content="Cre8teGPT" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:locale:alternate" content="en_GB" />
        <meta property="og:locale:alternate" content="en_CA" />
        <meta property="og:locale:alternate" content="en_AU" />
        <meta property="og:locale:alternate" content="en_NZ" />
        <meta property="og:locale:alternate" content="en_IE" />
        <meta property="og:locale:alternate" content="en_ZA" />
        <meta property="og:locale:alternate" content="en_IN" />
        <meta property="og:locale:alternate" content="en_SG" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Cre8teGPT" />
        <meta name="twitter:creator" content="@Cre8teGPT" />
        {/* <meta name="twitter:title" content={title + " | Hundreds of AI Tools in One Platform"} />
        <meta name="twitter:description" content="Cre8teGPT offers premium AI tools for content creators, including AI chat, image and video generators, article and lyrics generators, and various content assistants and agents to enhance your creative workflow." />
        <meta name="twitter:image" content="/images/logo/logo-big.png" /> */}


        <meta name="robots" content="index, follow" />
        <meta name="googlebot" content="index, follow" />
        <meta name="bingbot" content="index, follow" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black" />
        <meta name="apple-mobile-web-app-title" content="Cre8teGPT" />
        <meta name="application-name" content="Cre8teGPT" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="msapplication-navbutton-color" content="#ffffff" />
        <meta name="mobile-web-app-capable" content="yes" />

        <link rel="canonical" href={canonicalUrl} />
        
        <link rel="icon" href="/favicon.ico" />

        {/* V2 CSS Files */}
        <link rel="stylesheet preload" href="/css/plugins/fontawesome-6.css" as="style" />
        <link rel="stylesheet preload" href="/css/plugins/unicons.css" as="style" />
        <link rel="stylesheet preload" href="/css/plugins/swiper.min.css" as="style" />
        <link rel="stylesheet preload" href="/css/vendor/magnific-popup.css" as="style" />
        <link rel="stylesheet preload" href="/css/vendor/metismenu.css" as="style" />

        {/* V2 JS Files */}
        {/* <script src="/js/vendor/jquery.min.js"></script>
        <script src="/js/vendor/metismenu.js" defer=""></script>
        <script src="/js/vendor/imagesloaded.pkgd.min.js" defer=""></script>
        <script src="/js/vendor/isotope.pkgd.min.js" defer=""></script>
        <script src="/js/vendor/magnific-popup.js" defer=""></script>
        <script src="/js/plugins/swiper.js" defer=""></script>
        <script src="/js/plugins/contact.form.js" defer=""></script>
        <script src="/js/main.js"></script> */}

        <link rel="stylesheet" href="owl-carousel/owl.theme.css" />
      </Head>
    </>
  );
};

export default PageHead;

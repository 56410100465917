const generators = [
    {
        name: "AI Chat",
        description: "Running GPT-4o, our AI chat tool can help you generate human-like text responses to any prompt or question you provide.",
        slug: "ai-chat",
        url: "/tools/generators/ai-chat",
        icon: "/images/generator-icon/text_line.png",
        badge: "featured",
    },
    {
        name: "Image Generator",
        description: "Create stunning images for your website, social media, or marketing campaigns through our conversational Image Generator.",
        slug: "image-generator",
        url: "/tools/generators/image-generator",
        icon: "/images/generator-icon/photo_line.png",
        badge: "featured",
    },
    {
        name: "Image Generator V2",
        description: "Create stunning images for your website, social media, or marketing campaigns. Input a description and get a custom image.",
        slug: "image-generator-v2",
        url: "/tools/generators/image-generator-v2",
        icon: "/images/generator-icon/photo_line_2.png",
        badge: "new",
    },
    {
        name: "GIF Generator",
        id: "gif-generator",
        description: "Generate custom GIFs for your social media, blog, or website by providing a custom description or prompt.",
        slug: "gif-generator",
        url: "/tools/generators/gif-generator",
        icon: "/images/generator-icon/gif_generator.png",
        badge: "",
    },
    {
        name: "Face Generator",
        description: "Generate/Edit realistic human faces, avatars, or characters from your own photos or custom settings. Perfect for games and animations.",
        slug: "face-generator",
        url: "/tools/generators/face-generator",
        icon: "/images/generator-icon/face_generator.png",
        badge: ""
    },
    {
        name: "Avatar Generator",
        description: "Create a cute avatar from your photo. Choose from a variety of styles and customize your avatar with different features.",
        slug: "avatar-generator",
        url: "/tools/generators/avatar-generator",
        icon: "/images/generator-icon/avatar_line.png",
        badge: "",
    },
    {
        name: "Music Generator",
        description: "Generate music tracks, beats, and melodies for your videos, podcasts, or creative projects from a single prompt.",
        slug: "music-generator",
        url: "/tools/generators/music-generator",
        icon: "/images/generator-icon/music_line.png",
        badge: "",
    },
    {
        name: "Lyrics Generator",
        description: "Generate song lyrics for your music project, podcast, or video content with our AI-powered lyrics generator.",
        slug: "lyrics-generator",
        url: "/tools/generators/lyrics-generator",
        icon: "/images/generator-icon/lyrics_line.png",
        badge: "",
    },
    {
        name: "Video Generator",
        description: "Quickly create engaging videos for your business. Customize scenes, characters, and more. No video editing skills required.",
        slug: "video-generator",
        url: "/tools/generators/video-generator",
        icon: "/images/generator-icon/video-camera_line.png",
        badge: "coming",
    },
    {
        name: "Article Generator",
        description: "Stop staring at a blank page. Generate high-quality, SEO-friendly articles from keywords, domains, or URLs with ease.",
        slug: "article-generator",
        url: "/tools/generators/article-generator",
        icon: "/images/generator-icon/article_line.png",
        badge: "featured",
    },
    {
        name: "Course Generator",
        description: "Create engaging and personalized online courses, tutorials, or training materials. Perfect for educators and content creators.",
        slug: "course-generator",
        url: "/tools/generators/course-generator",
        icon: "/images/generator-icon/course_line.png",
        badge: "",
    },
    {
        name: "Comic Generator",
        description: "Produce comic strips, graphic novels, or webcomics with custom characters, scenes, storylines, and more. Completely AI-generated.",
        slug: "comic-generator",
        url: "/tools/generators/comic-generator",
        icon: "/images/generator-icon/comic_generator.png",
        badge: "hot",
    },
    {
        name: "Ad Copy Generator",
        description: "Create compelling ad copy for your marketing campaigns. Generate text for social media ads, Google ads, and more.",
        slug: "ad-copy-generator",
        url: "/tools/generators/ad-copy-generator",
        icon: "/images/generator-icon/ad-copy_line.png",
        badge: "",
    },
    {
        name: "Marketing Email Generator",
        description: "Generate marketing emails for your business or brand. Customize templates, subject lines, and content with ease.",
        slug: "marketing-email-generator",
        url: "/tools/generators/marketing-email-generator",
        icon: "/images/generator-icon/email_line.png",
        badge: "",
    },
    {
        name: "Recipe Generator",
        description: "Our recipe generator suggests delicious dishes with pictures based on available ingredients, helping you cook with what you have at home.",
        slug: "recipe-generator",
        url: "/tools/generators/recipe-generator",
        icon: "/images/generator-icon/recipe_line.png",
        badge: "",
    },
    {
        name: "Image To 3D Model",
        description: "Convert 2D images into 3D models in seconds. Perfect for game development, animation, and 3D printing.",
        slug: "image-to-3d",
        url: "/tools/generators/image-to-3d",
        icon: "/images/generator-icon/image_to_3d_model.png",
        badge: "",
    },
    {
        name: "Text to Speech",
        description: "Convert any text into a human-like voice. Choose from multiple languages, accents, and voices. Perfect for podcasts, videos, and more.",
        slug: "text-to-speech",
        url: "/tools/generators/text-to-speech",
        icon: "/images/generator-icon/text-voice_line.png",
        badge: "",
    },
    {
        name: "Speech to Text",
        description: "Convert any recording or audio file into text. 100% accurate and fast transcription with AI. Perfect for interviews, lectures, and more.",
        slug: "speech-to-text",
        url: "/tools/generators/speech-to-text",
        icon: "/images/generator-icon/voice_line.png",
        badge: "",
    },
    {
        name: "Website Cloner (Screenshot to HTML)",
        description: "Clone any webpage's HTML/CSS codes from a screenshot in a matter of seconds. Replicate structure and design with ease.",
        slug: "website-cloner/screenshot-to-html",
        url: "/tools/generators/website-cloner/screenshot-to-html",
        icon: "/images/generator-icon/website_cloner.png",
        badge: ""
    },
    {
        name: "XML Sitemap Generator",
        description: "Create an XML sitemap for your website to improve search engine optimization (SEO) with our free non-AI sitemap generator.",
        slug: "xml-sitemap-generator",
        url: "/tools/generators/xml-sitemap-generator",
        icon: "/images/generator-icon/xml-sitemap_line.png",
        badge: "free",
    },
    {
        name: "Story Generator",
        description: "Generate creative story ideas, plot twists, or character descriptions. Perfect for writers, game developers, and storytellers.",
        slug: "story-generator",
        url: "/tools/generators/story-generator",
        icon: "/images/generator-icon/story_generator.png",
        badge: "",
    },
    {
        name: "Website Generator",
        description: "Create a professional website in minutes with our AI-powered website generator. No coding or design skills required.",
        slug: "website-generator",
        url: "/tools/generators/website-generator",
        icon: "/images/generator-icon/website-design_line.png",
        badge: "coming",
    },
    {
        name: "Multimodal Content Generator",
        description: "Create comprehensive multiple media content from a single prompt. Generate text, images, videos, and more.",
        slug: "multimodal-generator",
        url: "/tools/generators/multimodal-generator",
        icon: "/images/generator-icon/multimodal-generator_line.png",
        badge: "coming",
    },
    {
        name: "Code Generator",
        description: "Generate code snippets, scripts, or templates for your programming projects with our AI-powered code generator.",
        slug: "code-generator",
        url: "/tools/generators/code-generator",
        icon: "/images/generator-icon/code-editor_line.png",
        badge: "coming",
    },
    {
        name: "Logo Generator",
        description: "Design a unique logo for your brand or business. Customize colors, fonts, and icons with our AI-powered logo generator.",
        slug: "logo-generator",
        url: "/tools/generators/logo-generator",
        icon: "/images/logo/favicon.png",
        badge: "coming",
    },
    {
        name: "YouTube Thumbnail Generator",
        description: "Create eye-catching thumbnails for your YouTube videos. Customize text, colors, images, and more.",
        slug: "youtube-thumbnail-generator",
        url: "/tools/generators/youtube-thumbnail-generator",
        icon: "/images/generator-icon/youtube-thumbnail-generator_line.png",
        badge: "coming",
    },
    // {
    //     name: "AI Podcast Generator",
    //     description: "Creates full podcast episodes, including script, voice acting, and background music.",
    //     slug: "ai-podcast-generator",
    //     url: "/tools/generators/ai-podcast-generator",
    //     icon: "/images/generator-icon/podcast.png",
    //     badge: "coming",
    // },
    {
        name: "Virtual Influencer Creator",
        description: "Generates AI-powered virtual influencers with unique personalities and content styles.",
        slug: "virtual-influencer-creator",
        url: "/tools/generators/virtual-influencer-creator",
        icon: "/images/generator-icon/virtual_influencer.png",
        badge: "coming",
    },
    {
        name: "Augmented Reality Content Generator",
        description: "Creates AR experiences for marketing or educational purposes.",
        slug: "ar-content-generator",
        url: "/tools/generators/ar-content-generator",
        icon: "/images/generator-icon/ar.png",
        badge: "coming",
    },
    // {
    //     name: "Product Description Optimizer",
    //     description: "Generates and optimizes product descriptions for e-commerce platforms.",
    //     slug: "product-description-optimizer",
    //     icon: "/images/generator-icon/product_description.png",
    //     badge: "coming",
    // },
    {
        name: "AI Meme Generator",
        description: "Creates trending memes based on current events and user preferences.",
        slug: "ai-meme-generator",
        url: "/tools/generators/ai-meme-generator",
        icon: "/images/generator-icon/meme.png",
        badge: "coming",
    },
    {
        name: "Personalized Learning Content Creator",
        description: "Generates educational content tailored to individual learning styles and progress.",
        slug: "personalized-learning-content-creator",
        url: "/tools/generators/personalized-learning-content-creator",
        icon: "/images/generator-icon/learning_content.png",
        badge: "coming",
    },
    {
        name: "AI Fashion Designer",
        description: "Creates unique clothing designs and patterns.",
        slug: "ai-fashion-designer",
        url: "/tools/generators/ai-fashion-designer",
        icon: "/images/generator-icon/fashion_design.png",
        badge: "coming",
    },
    {
        name: "AI Infographic Designer",
        description: "Creates visually appealing infographics from data inputs.",
        slug: "ai-infographic-designer",
        url: "/tools/generators/ai-infographic-designer",
        icon: "/images/generator-icon/infographic.png",
        badge: "coming",
    },
    {
        name: "Virtual Reality Experience Generator",
        description: "Builds immersive VR environments for various applications.",
        slug: "vr-experience-generator",
        url: "/tools/generators/vr-experience-generator",
        icon: "/images/generator-icon/vr.png",
        badge: "coming",
    },
    {
        name: "AI-Powered Jingle Creator",
        description: "Composes catchy jingles and short musical pieces for ads or intros.",
        slug: "ai-jingle-creator",
        url: "/tools/generators/ai-jingle-creator",
        icon: "/images/generator-icon/jingle.png",
        badge: "coming",
    },
    {
        name: "Personalized Children's Book Generator",
        description: "Creates custom storybooks tailored to a child's preferences and learning goals.",
        slug: "personalized-childrens-book-generator",
        url: "/tools/generators/personalized-childrens-book-generator",
        icon: "/images/generator-icon/childrens_book.png",
        badge: "coming",
    },
    {
        name: "AI Unboxing Video Generator",
        description: "Produces unboxing videos for products with customizable styles and narratives.",
        slug: "ai-unboxing-video-generator",
        url: "/tools/generators/ai-unboxing-video-generator",
        icon: "/images/generator-icon/unboxing_video.png",
        badge: "coming",
    },
    {
        name: "AI Cocktail Recipe Generator",
        description: "Invents new cocktail recipes based on available ingredients or flavor profiles.",
        slug: "ai-cocktail-recipe-generator",
        url: "/tools/generators/ai-cocktail-recipe-generator",
        icon: "/images/generator-icon/cocktail_recipe.png",
        badge: "coming",
    },
    {
        name: "Virtual Fashion Show Creator",
        description: "Generates virtual fashion shows with AI models and designs.",
        slug: "virtual-fashion-show-creator",
        url: "/tools/generators/virtual-fashion-show-creator",
        icon: "/images/generator-icon/fashion_show.png",
        badge: "coming",
    },
    {
        name: "AI-Powered Workout Routine Generator",
        description: "Creates personalized workout routines with instructional videos.",
        slug: "ai-workout-routine-generator",
        url: "/tools/generators/ai-workout-routine-generator",
        icon: "/images/generator-icon/workout_routine.png",
        badge: "coming",
    },
    {
        name: "Automated News Report Generator",
        description: "Produces news reports from data inputs and current events.",
        slug: "automated-news-report-generator",
        url: "/tools/generators/automated-news-report-generator",
        icon: "/images/generator-icon/news_report.png",
        badge: "coming",
    },
    {
        name: "AI Crossword Puzzle Creator",
        description: "Generates crossword puzzles with customizable themes and difficulty levels.",
        slug: "ai-crossword-puzzle-creator",
        url: "/tools/generators/ai-crossword-puzzle-creator",
        icon: "/images/generator-icon/crossword_puzzle.png",
        badge: "coming",
    },
    {
        name: "Virtual Interior Designer",
        description: "Creates 3D interior designs based on user preferences and space constraints.",
        slug: "virtual-interior-designer",
        url: "/tools/generators/virtual-interior-designer",
        icon: "/images/generator-icon/interior_design.png",
        badge: "coming",
    },
    {
        name: "AI Tarot Card Reading Generator",
        description: "Produces personalized tarot card readings with interpretations.",
        slug: "ai-tarot-card-reading-generator",
        url: "/tools/generators/ai-tarot-card-reading-generator",
        icon: "/images/generator-icon/tarot_reading.png",
        badge: "coming",
    },
    {
        name: "Personalized Travel Itinerary Generator",
        description: "Creates custom travel plans based on preferences, budget, and destination.",
        slug: "personalized-travel-itinerary-generator",
        url: "/tools/generators/personalized-travel-itinerary-generator",
        icon: "/images/generator-icon/travel_itinerary.png",
        badge: "coming",
    },
    {
        name: "AI Poetry Generator",
        description: "Composes various styles of poetry, from haikus to sonnets.",
        slug: "ai-poetry-generator",
        url: "/tools/generators/ai-poetry-generator",
        icon: "/images/generator-icon/poetry.png",
        badge: "coming",
    },
    {
        name: "Virtual Product Prototype Generator",
        description: "Creates 3D prototypes of product ideas for visualization.",
        slug: "virtual-product-prototype-generator",
        url: "/tools/generators/virtual-product-prototype-generator",
        icon: "/images/generator-icon/product_prototype.png",
        badge: "coming",
    },
    {
        name: "AI-Powered Resume Builder",
        description: "Generates tailored resumes based on job descriptions and personal experiences.",
        slug: "ai-resume-builder",
        url: "/tools/generators/ai-resume-builder",
        icon: "/images/generator-icon/resume_builder.png",
        badge: "coming",
    },
    {
        name: "Contextual Chatbot Generator",
        description: "Creates specialized chatbots for websites or applications.",
        slug: "contextual-chatbot-generator",
        url: "/tools/generators/contextual-chatbot-generator",
        icon: "/images/generator-icon/chatbot.png",
        badge: "coming",
    },
    {
        name: "AI Art Installation Designer",
        description: "Generates concepts for interactive art installations.",
        slug: "ai-art-installation-designer",
        url: "/tools/generators/ai-art-installation-designer",
        icon: "/images/generator-icon/art_installation.png",
        badge: "coming",
    },
    {
        name: "Personalized Emoji Creator",
        description: "Designs custom emojis based on user input or facial recognition.",
        slug: "personalized-emoji-creator",
        url: "/tools/generators/personalized-emoji-creator",
        icon: "/images/generator-icon/emoji_creator.png",
        badge: "coming",
    }
];

const editors = [
    {
        name: "Photo Editor",
        description: "Edit and enhance your photos with our AI-powered photo editor. Request updates via AI chat.",
        slug: "photo-editor",
        url: "/tools/editors/photo-editor",
        icon: "/images/editor-icon/photo-editor_line.png",
        badge: "featured",
    },
    {

        name: "Photo Editor V2",
        description: "Edit and enhance your photos with our AI-powered photo editor. Add filters, effects, and more.",
        slug: "photo-editor-v2",
        url: "/tools/editors/photo-editor-v2",
        icon: "/images/editor-icon/photo_editor_v2.png",
        badge: "new",
    },
    {
        name: "Video Editor",
        description: "Edit and enhance your videos with our AI-powered video editor. Add effects, trim clips, and more.",
        slug: "video-editor",
        url: "/tools/editors/video-editor",
        icon: "/images/editor-icon/video-editor_line.png",
        badge: "coming",
    },
    {
        name: "Audio Editor",
        description: "Enhances audio quality, removes background noise, and even changes voice tones.",
        slug: "ai-audio-editor",
        url: "/tools/editors/ai-audio-editor",
        icon: "/images/editor-icon/audio_editor.png",
        badge: "coming",
    },
    {
        name: "3D Model Editor",
        description: "Allows editing and optimization of 3D models for AR/VR applications.",
        slug: "3d-model-editor",
        url: "/tools/editors/3d-model-editor",
        icon: "/images/editor-icon/3d_model.png",
        badge: "coming",
    },
    {
        name: "Background Remover",
        description: "Remove backgrounds from images in seconds and as easy as clicking a button.",
        slug: "background-remover",
        url: "/tools/editors/background-remover",
        icon: "/images/editor-icon/background_remover.png",
        badge: "",
    },
    {
        name: "Video Background Remover",
        description: "Remove backgrounds from videos in seconds and as easy as clicking a button.",
        slug: "video-background-remover",
        url: "/tools/editors/video-background-remover",
        icon: "/images/editor-icon/video_background_remover.png",
        badge: "",
    },
    {
        name: "Background Replacer",
        description: "Replace backgrounds in images with our AI-powered background replacer tool.",
        slug: "background-replacer",
        url: "/tools/editors/background-replacer",
        icon: "/images/editor-icon/background_replacer.png",
        badge: "",
    },
    {
        name: "Foreground Remover",
        description: "Remove foreground elements from images with our AI-powered foreground remover tool.",
        slug: "foreground-remover",
        url: "/tools/editors/foreground-remover",
        icon: "/images/editor-icon/foreground_remover.png",
        badge: "hot",
    },
    {
        name: "Image Enhancer",
        description: "Enhance image quality, adjust colors, and apply filters with our AI-powered image enhancer tool.",
        slug: "image-enhancer",
        url: "/tools/editors/image-enhancer",
        icon: "/images/editor-icon/image_enhancer.png",
        badge: "coming",
    },
    {
        name: "Image Upscaler",
        description: "Increase image resolution without losing quality with our AI-powered image upscaler tool.",
        slug: "image-upscaler",
        url: "/tools/editors/image-upscaler",
        icon: "/images/editor-icon/image_upscaler.png",
        badge: "",
    },
    {
        name: "Image Expander",
        description: "Broaden the field of view in images without distortion with our AI-powered image expander tool.",
        slug: "image-expander",
        url: "/tools/editors/image-expander",
        icon: "/images/editor-icon/image_expander.png",
        badge: "",
    },
    {
        name: "Vocal Removal",
        description: "Separate vocals from music tracks. Extract drums, bass, piano, and other instruments with ease.",
        slug: "vocal-removal",
        url: "/tools/editors/vocal-removal",
        icon: "/images/editor-icon/vocal_removal.png",
        badge: "",
    },
    {
        name: "Voice Changer",
        description: "Change your voice to sound like a celebrity, politician, cartoon character, or even a robot.",
        slug: "voice-changer",
        url: "/tools/editors/voice-changer",
        icon: "/images/editor-icon/voice_changer.png",
        badge: "coming",
    },
    {
        name: "Voice Cloner",
        description: "Creates realistic voice clones from audio recordings. Perfect for dubbing and voiceovers.",
        slug: "voice-cloner",
        url: "/tools/editors/voice-cloner",
        icon: "/images/editor-icon/voice_cloner.png",
        badge: "",
    },
    {
        name: "Voice Converter",
        description: "Converts your singing voice to sound like a different singer. Sing like your favorite artists.",
        slug: "voice-converter",
        url: "/tools/editors/voice-converter",
        icon: "/images/editor-icon/voice_converter.png",
        badge: "coming",
    },
    {
        name: "Face Swapper",
        description: "Swap faces between two photos and create hilarious results to share with friends and family.",
        slug: "face-swapper",
        url: "/tools/editors/face-swapper",
        icon: "/images/editor-icon/face_swapper.png",
        badge: "",
    },
    {
        name: "Live Portrait",
        description: "Bring your photos to life, add mimics, emotions, expressions, and lip-sync to your static portraits.",
        slug: "live-portrait",
        url: "/tools/editors/live-portrait",
        icon: "/images/editor-icon/live_portrait.png",
        badge: "",
    },
    {
        name: "Smart Color Grading Tool",
        description: "Automatically color grades videos and photos to match specific moods or styles.",
        slug: "smart-color-grading",
        url: "/tools/editors/smart-color-grading",
        icon: "/images/editor-icon/color_grading.png",
        badge: "coming",
    },
    {
        name: "AI Storyboard Editor",
        description: "Helps visualize and edit video storyboards with AI-generated sketches.",
        slug: "ai-storyboard-editor",
        url: "/tools/editors/ai-storyboard-editor",
        icon: "/images/editor-icon/storyboard.png",
        badge: "coming",
    },
    {
        name: "Motion Graphics Editor",
        description: "Creates and edits complex motion graphics with AI assistance.",
        slug: "motion-graphics-editor",
        url: "/tools/editors/motion-graphics-editor",
        icon: "/images/editor-icon/motion_graphics.png",
        badge: "coming",
    },
    {
        name: "AI-powered Music Remixer",
        description: "Remixes and edits music tracks, adjusting tempo, key, and style.",
        slug: "ai-music-remixer",
        url: "/tools/editors/ai-music-remixer",
        icon: "/images/editor-icon/music_remixer.png",
        badge: "coming",
    },
    {
        name: "Virtual Set Designer",
        description: "Edits and creates virtual sets for video production.",
        slug: "virtual-set-designer",
        url: "/tools/editors/virtual-set-designer",
        icon: "/images/editor-icon/virtual_set.png",
        badge: "coming",
    },
    {
        name: "Deepfake Detection and Correction Tool",
        description: "Identifies and helps edit out deepfake content in videos.",
        slug: "deepfake-detection-correction",
        url: "/tools/editors/deepfake-detection-correction",
        icon: "/images/editor-icon/deepfake_detection.png",
        badge: "coming",
    },
    {
        name: "AI Subtitling and Captioning Editor",
        description: "Automatically generates and edits subtitles in multiple languages.",
        slug: "ai-subtitling-captioning",
        url: "/tools/editors/ai-subtitling-captioning",
        icon: "/images/editor-icon/subtitling.png",
        badge: "coming",
    },
    {
        name: "Gesture-based Video Editor",
        description: "Allows video editing through hand gestures in a VR environment.",
        slug: "gesture-video-editor",
        url: "/tools/editors/gesture-video-editor",
        icon: "/images/editor-icon/gesture_editor.png",
        badge: "coming",
    },
    {
        name: "AI-Powered Video Upscaling Tool",
        description: "Enhances low-resolution videos to higher quality.",
        slug: "ai-video-upscaling",
        url: "/tools/editors/ai-video-upscaling",
        icon: "/images/editor-icon/video_upscaling.png",
        badge: "coming",
    },
    {
        name: "Voice Emotion Editor",
        description: "Adjusts the emotional tone of voice recordings.",
        slug: "voice-emotion-editor",
        url: "/tools/editors/voice-emotion-editor",
        icon: "/images/editor-icon/voice_emotion.png",
        badge: "coming",
    },
    {
        name: "AI Background Replacement Tool",
        description: "Intelligently replaces or modifies video and image backgrounds.",
        slug: "ai-background-replacement",
        url: "/tools/editors/ai-background-replacement",
        icon: "/images/editor-icon/background_replacement.png",
        badge: "coming",
    },
    {
        name: "Contextual Video Highlight Generator",
        description: "Automatically creates highlights from longer video content.",
        slug: "contextual-video-highlights",
        url: "/tools/editors/contextual-video-highlights",
        icon: "/images/editor-icon/video_highlights.png",
        badge: "coming",
    },
    {
        name: "AI-Driven Audio Mixing Tool",
        description: "Balances and optimizes multi-track audio recordings.",
        slug: "ai-audio-mixing",
        url: "/tools/editors/ai-audio-mixing",
        icon: "/images/editor-icon/audio_mixing.png",
        badge: "coming",
    },
    {
        name: "Smart Object Removal Editor",
        description: "Removes or replaces objects in videos while maintaining visual consistency.",
        slug: "smart-object-removal",
        url: "/tools/editors/smart-object-removal",
        icon: "/images/editor-icon/object_removal.png",
        badge: "coming",
    },
    {
        name: "AI-Powered Video Stabilization Tool",
        description: "Smooths shaky video footage.",
        slug: "ai-video-stabilization",
        url: "/tools/editors/ai-video-stabilization",
        icon: "/images/editor-icon/video_stabilization.png",
        badge: "coming",
    },
    {
        name: "Automated Video Chaptering Tool",
        description: "Intelligently divides long-form videos into chapters.",
        slug: "automated-video-chaptering",
        url: "/tools/editors/automated-video-chaptering",
        icon: "/images/editor-icon/video_chaptering.png",
        badge: "coming",
    },
    {
        name: "AI Skin Retouching Tool",
        description: "Provides advanced, natural-looking skin retouching in photos and videos.",
        slug: "ai-skin-retouching",
        url: "/tools/editors/ai-skin-retouching",
        icon: "/images/editor-icon/skin_retouching.png",
        badge: "coming",
    },
    {
        name: "Intelligent Frame Interpolation Tool",
        description: "Increases video frame rates for smoother motion.",
        slug: "frame-interpolation",
        url: "/tools/editors/frame-interpolation",
        icon: "/images/editor-icon/frame_interpolation.png",
        badge: "coming",
    },
    {
        name: "AI-Driven Color Restoration Tool",
        description: "Restores colors in old or faded photos and videos.",
        slug: "color-restoration",
        url: "/tools/editors/color-restoration",
        icon: "/images/editor-icon/color_restoration.png",
        badge: "coming",
    },
    {
        name: "Smart Vertical Video Adapter",
        description: "Adapts horizontal videos for vertical formats (and vice versa) while maintaining key visual elements.",
        slug: "vertical-video-adapter",
        url: "/tools/editors/vertical-video-adapter",
        icon: "/images/editor-icon/vertical_video.png",
        badge: "coming",
    },
    {
        name: "AI Lip-Sync Editor",
        description: "Adjusts video to match dubbed audio in different languages.",
        slug: "ai-lip-sync",
        url: "/tools/editors/ai-lip-sync",
        icon: "/images/editor-icon/lip_sync.png",
        badge: "coming",
    },
    {
        name: "Intelligent Video Compression Tool",
        description: "Optimizes video file size while maintaining quality.",
        slug: "intelligent-video-compression",
        url: "/tools/editors/intelligent-video-compression",
        icon: "/images/editor-icon/video_compression.png",
        badge: "coming",
    },
    {
        name: "AI-Powered Audio Separation Tool",
        description: "Separates audio tracks into individual instruments or voices.",
        slug: "audio-separation",
        url: "/tools/editors/audio-separation",
        icon: "/images/editor-icon/audio_separation.png",
        badge: "coming",
    },
    {
        name: "Smart Cinematography Editor",
        description: "Suggests and applies professional cinematography techniques to amateur footage.",
        slug: "smart-cinematography",
        url: "/tools/editors/smart-cinematography",
        icon: "/images/editor-icon/cinematography.png",
        badge: "coming",
    },
    {
        name: "AI Slow Motion Generator",
        description: "Creates high-quality slow-motion effects from standard frame rate videos.",
        slug: "ai-slow-motion",
        url: "/tools/editors/ai-slow-motion",
        icon: "/images/editor-icon/slow_motion.png",
        badge: "coming",
    },
    {
        name: "Automated Video Censoring Tool",
        description: "Intelligently blurs or replaces sensitive content in videos.",
        slug: "automated-video-censoring",
        url: "/tools/editors/automated-video-censoring",
        icon: "/images/editor-icon/video_censoring.png",
        badge: "coming",
    },
    {
        name: "AI-Driven Audio Restoration Tool",
        description: "Removes noise and enhances quality of old or damaged audio recordings.",
        slug: "audio-restoration",
        url: "/tools/editors/audio-restoration",
        icon: "/images/editor-icon/audio_restoration.png",
        badge: "coming",
    },
    {
        name: "Smart Aspect Ratio Adapter",
        description: "Intelligently adapts content for different aspect ratios across various platforms.",
        slug: "aspect-ratio-adapter",
        url: "/tools/editors/aspect-ratio-adapter",
        icon: "/images/editor-icon/aspect_ratio.png",
        badge: "coming",
    },
    {
        name: "AI-Powered Text Overlay Generator",
        description: "Automatically generates and positions text overlays for videos.",
        slug: "text-overlay-generator",
        url: "/tools/editors/text-overlay-generator",
        icon: "/images/editor-icon/text_overlay.png",
        badge: "coming",
    },
    {
        name: "Automated B-Roll Suggester",
        description: "Suggests and inserts relevant B-roll footage based on video content.",
        slug: "b-roll-suggester",
        url: "/tools/editors/b-roll-suggester",
        icon: "/images/editor-icon/b_roll.png",
        badge: "coming",
    }
];

const assistants = [
    {
        name: "Writing Assistant",
        description: "Improve your writing with our AI-powered writing assistant. Get suggestions for grammar, style, and more.",
        slug: "writing-assistant",
        url: "/tools/assistants/writing-assistant",
        icon: "/images/assistant-icon/writing-assistant_line.png",
        badge: "hot",
    },
    {
        name: "PDF Chat Assistant",
        description: "Chat with any PDF document. Simply upload your PDF, and our tool will generate a chatbot based on the content of your document.",
        slug: "pdf-chat-assistant",
        url: "/tools/assistants/pdf-chat-assistant",
        icon: "/images/assistant-icon/pdf-chat-assistant_line.png",
        badge: "",
    },
    {
        name: "Website Chat Assistant",
        description: "Transform any website into a chatbot. Get answers to your questions, find products, gain insights, and more.",
        slug: "website-chat-assistant",
        url: "/tools/assistants/website-chat-assistant",
        icon: "/images/assistant-icon/website_chat_assistant.png",
        badge: "",
    },
    {
        name: "YouTube Video Assistant",
        description: "Get help with your YouTube video ideas, titles, descriptions, tags, and more with our AI-powered YouTube video assistant.",
        slug: "youtube-video-assistant",
        url: "/tools/assistants/youtube-video-assistant",
        icon: "/images/assistant-icon/youtube-assistant_line.png",
        badge: "",
    },
    {
        name: "YouTube Video Translator",
        description: "Translate YouTube videos into multiple languages with our AI-powered YouTube video translator.",
        slug: "youtube-video-translator",
        url: "/tools/assistants/youtube-video-translator",
        icon: "/images/assistant-icon/youtube_translator.png",
        badge: "",
    },
    {
        name: "YouTube Channel Analyzer",
        description: "Analyze any YouTube channel, video, or playlist with our AI-powered YouTube channel analyzer.",
        slug: "youtube-channel-analyzer",
        url: "/tools/assistants/youtube-channel-analyzer",
        icon: "/images/assistant-icon/youtube-channel-analyzer.png",
        badge: "",
    },
    {
        name: "YouTube Script Assistant",
        description: "Generate video scripts, outlines, or ideas for your YouTube channel with our AI-powered YouTube script assistant.",
        slug: "youtube-script-assistant",
        url: "/tools/assistants/youtube-script-assistant",
        icon: "/images/assistant-icon/youtube-script_line.png",
        badge: "",
    },
    {
        name: "YouTube Summarizer",
        description: "Summarize any YouTube video into a concise text summary with our AI-powered YouTube video summarizer.",
        slug: "youtube-summarizer",
        url: "/tools/assistants/youtube-summarizer",
        icon: "/images/assistant-icon/youtube-summarizer.png",
        badge: "",
    },
    {
        name: "Research Assistant",
        description: "Get help with your research projects, papers, or articles with our AI-powered research assistant.",
        slug: "research-assistant",
        url: "/tools/assistants/research-assistant",
        icon: "/images/assistant-icon/research-assistant_line.png",
        badge: "coming",
    },
    {
        name: "Multilingual Content Optimization Assistant",
        description: "Helps optimize content for multiple languages and cultures simultaneously.",
        slug: "multilingual-content-optimization",
        url: "/tools/assistants/multilingual-content-optimization",
        icon: "/images/assistant-icon/multilingual.png",
        badge: "coming",
    },
    {
        name: "AI Storytelling Structure Advisor",
        description: "Suggests narrative structures and plot developments for various content types.",
        slug: "storytelling-structure-advisor",
        url: "/tools/assistants/storytelling-structure-advisor",
        icon: "/images/assistant-icon/storytelling.png",
        badge: "coming",
    },
    {
        name: "Visual Hierarchy Optimization Assistant",
        description: "Helps improve the visual flow and hierarchy of designs and layouts.",
        slug: "visual-hierarchy-optimization",
        url: "/tools/assistants/visual-hierarchy-optimization",
        icon: "/images/assistant-icon/visual_hierarchy.png",
        badge: "coming",
    },
    {
        name: "Emotional Impact Predictor",
        description: "Analyzes content to predict its emotional impact on different audience segments.",
        slug: "emotional-impact-predictor",
        url: "/tools/assistants/emotional-impact-predictor",
        icon: "/images/assistant-icon/emotional_impact.png",
        badge: "coming",
    },
    {
        name: "AI-Powered Controversy Detector",
        description: "Identifies potentially controversial elements in content before publication.",
        slug: "controversy-detector",
        url: "/tools/assistants/controversy-detector",
        icon: "/images/assistant-icon/controversy_detector.png",
        badge: "coming",
    },
    {
        name: "SEO Strategy Evolution Assistant",
        description: "Continuously updates SEO strategies based on changing algorithms and trends.",
        slug: "seo-strategy-evolution",
        url: "/tools/assistants/seo-strategy-evolution",
        icon: "/images/assistant-icon/seo_strategy.png",
        badge: "coming",
    },
    {
        name: "Content Authenticity Verifier",
        description: "Helps ensure the originality and authenticity of created content.",
        slug: "content-authenticity-verifier",
        url: "/tools/assistants/content-authenticity-verifier",
        icon: "/images/assistant-icon/authenticity_verifier.png",
        badge: "coming",
    },
    {
        name: "Audience Persona Development Assistant",
        description: "Helps create and refine detailed audience personas for targeted content creation.",
        slug: "audience-persona-development",
        url: "/tools/assistants/audience-persona-development",
        icon: "/images/assistant-icon/audience_persona.png",
        badge: "coming",
    },
    {
        name: "AI Ethics Consultant",
        description: "Provides guidance on ethical considerations in content creation and AI usage.",
        slug: "ai-ethics-consultant",
        url: "/tools/assistants/ai-ethics-consultant",
        icon: "/images/assistant-icon/ethics_consultant.png",
        badge: "coming",
    },
    {
        name: "Neuromarketing Insights Assistant",
        description: "Applies neuroscience principles to optimize content for maximum engagement.",
        slug: "neuromarketing-insights",
        url: "/tools/assistants/neuromarketing-insights",
        icon: "/images/assistant-icon/neuromarketing.png",
        badge: "coming",
    },
    {
        name: "Gamification Strategy Advisor",
        description: "Suggests ways to incorporate game-like elements into content for increased engagement.",
        slug: "gamification-strategy-advisor",
        url: "/tools/assistants/gamification-strategy-advisor",
        icon: "/images/assistant-icon/gamification.png",
        badge: "coming",
    },
    {
        name: "Voice Search Optimization Assistant",
        description: "Helps optimize content for voice search queries and smart speakers.",
        slug: "voice-search-optimization",
        url: "/tools/assistants/voice-search-optimization",
        icon: "/images/assistant-icon/voice_search.png",
        badge: "coming",
    },
    {
        name: "Micro-Content Strategy Assistant",
        description: "Advises on creating and optimizing micro-content for platforms like TikTok or Instagram Stories.",
        slug: "micro-content-strategy",
        url: "/tools/assistants/micro-content-strategy",
        icon: "/images/assistant-icon/micro_content.png",
        badge: "coming",
    },
    {
        name: "Content Localization Advisor",
        description: "Provides culturally-aware suggestions for adapting content to different regions.",
        slug: "content-localization-advisor",
        url: "/tools/assistants/content-localization-advisor",
        icon: "/images/assistant-icon/localization.png",
        badge: "coming",
    },
    {
        name: "Sensory Branding Assistant",
        description: "Helps incorporate multi-sensory elements into brand content strategies.",
        slug: "sensory-branding",
        url: "/tools/assistants/sensory-branding",
        icon: "/images/assistant-icon/sensory_branding.png",
        badge: "coming",
    },
    {
        name: "AI-Powered A/B Testing Strategist",
        description: "Designs and analyzes A/B tests for content optimization.",
        slug: "ab-testing-strategist",
        url: "/tools/assistants/ab-testing-strategist",
        icon: "/images/assistant-icon/ab_testing.png",
        badge: "coming",
    },
    {
        name: "Inclusive Language Advisor",
        description: "Ensures content uses inclusive and accessible language for diverse audiences.",
        slug: "inclusive-language-advisor",
        url: "/tools/assistants/inclusive-language-advisor",
        icon: "/images/assistant-icon/inclusive_language.png",
        badge: "coming",
    },
    {
        name: "Content Lifecycle Optimization Assistant",
        description: "Advises on strategies to extend the lifecycle and value of created content.",
        slug: "content-lifecycle-optimization",
        url: "/tools/assistants/content-lifecycle-optimization",
        icon: "/images/assistant-icon/lifecycle_optimization.png",
        badge: "coming",
    },
    {
        name: "Behavioral Economics Content Advisor",
        description: "Applies behavioral economics principles to content strategies.",
        slug: "behavioral-economics-advisor",
        url: "/tools/assistants/behavioral-economics-advisor",
        icon: "/images/assistant-icon/behavioral_economics.png",
        badge: "coming",
    },
    {
        name: "Attention Economics Strategist",
        description: "Helps optimize content based on principles of attention economics in the digital age.",
        slug: "attention-economics-strategist",
        url: "/tools/assistants/attention-economics-strategist",
        icon: "/images/assistant-icon/attention_economics.png",
        badge: "coming",
    },
    {
        name: "Cross-Platform Content Synergy Advisor",
        description: "Ensures coherent messaging and optimal content distribution across various platforms.",
        slug: "cross-platform-synergy",
        url: "/tools/assistants/cross-platform-synergy",
        icon: "/images/assistant-icon/cross_platform.png",
        badge: "coming",
    },
    // {
    //     name: "AI-Driven Headline Optimizer",
    //     description: "Generates and tests multiple headline variations for maximum engagement.",
    //     slug: "headline-optimizer",
    //     url: "/tools/assistants/headline-optimizer",
    //     icon: "/images/assistant-icon/headline_optimizer.png",
    //     badge: "coming",
    // },
    {
        name: "Contextual Relevance Analyzer",
        description: "Assesses and enhances the contextual relevance of content for specific audiences or situations.",
        slug: "contextual-relevance-analyzer",
        url: "/tools/assistants/contextual-relevance-analyzer",
        icon: "/images/assistant-icon/contextual_relevance.png",
        badge: "coming",
    },
    {
        name: "Sentiment Analysis Advisor",
        description: "Analyzes and advises on the emotional tone of content and its potential impact.",
        slug: "sentiment-analysis-advisor",
        url: "/tools/assistants/sentiment-analysis-advisor",
        icon: "/images/assistant-icon/sentiment_analysis.png",
        badge: "coming",
    },
    {
        name: "Content Accessibility Checker",
        description: "Ensures content meets accessibility standards for users with diverse needs.",
        slug: "accessibility-checker",
        url: "/tools/assistants/accessibility-checker",
        icon: "/images/assistant-icon/accessibility.png",
        badge: "coming",
    },
    {
        name: "Trend Forecasting Assistant",
        description: "Predicts upcoming content trends and suggests ways to capitalize on them.",
        slug: "trend-forecasting",
        url: "/tools/assistants/trend-forecasting",
        icon: "/images/assistant-icon/trend_forecasting.png",
        badge: "coming",
    },
    {
        name: "Personal Brand Consistency Checker",
        description: "Ensures all content aligns with and reinforces the creator's personal brand.",
        slug: "brand-consistency-checker",
        url: "/tools/assistants/brand-consistency-checker",
        icon: "/images/assistant-icon/brand_consistency.png",
        badge: "coming",
    },
    {
        name: "Content Repurposing Strategist",
        description: "Suggests innovative ways to repurpose and reuse existing content across different formats and platforms.",
        slug: "content-repurposing-strategist",
        url: "/tools/assistants/content-repurposing-strategist",
        icon: "/images/assistant-icon/content_repurposing.png",
        badge: "coming",
    },
    {
        name: "User Experience Optimization Assistant",
        description: "Analyzes and suggests improvements for the overall user experience of digital content.",
        slug: "ux-optimization",
        url: "/tools/assistants/ux-optimization",
        icon: "/images/assistant-icon/ux_optimization.png",
        badge: "coming",
    },
    {
        name: "AI-Powered Content Scoring System",
        description: "Evaluates content based on multiple factors and provides an overall quality score with improvement suggestions.",
        slug: "content-scoring-system",
        url: "/tools/assistants/content-scoring-system",
        icon: "/images/assistant-icon/content_scoring.png",
        badge: "coming",
    },
    {
        name: "Interactive Content Advisor",
        description: "Provides strategies and ideas for creating more engaging, interactive content experiences.",
        slug: "interactive-content-advisor",
        url: "/tools/assistants/interactive-content-advisor",
        icon: "/images/assistant-icon/interactive_content.png",
        badge: "coming",
    },
    {
        name: "Content Personalization Engine",
        description: "Assists in creating and implementing personalized content strategies for individual users or segments.",
        slug: "content-personalization-engine",
        url: "/tools/assistants/content-personalization-engine",
        icon: "/images/assistant-icon/personalization_engine.png",
        badge: "coming",
    },
    {
        name: "Semantic SEO Optimization Assistant",
        description: "Helps optimize content for semantic search and topic relevance beyond just keywords.",
        slug: "semantic-seo-optimization",
        url: "/tools/assistants/semantic-seo-optimization",
        icon: "/images/assistant-icon/semantic_seo.png",
        badge: "coming",
    },
    {
        name: "Content Cohesion Analyzer",
        description: "Evaluates and improves the overall coherence and flow of content across different pieces and platforms.",
        slug: "content-cohesion-analyzer",
        url: "/tools/assistants/content-cohesion-analyzer",
        icon: "/images/assistant-icon/content_cohesion.png",
        badge: "coming",
    }
];

const agents = [
    {
        name: "Web Agent",
        description: "Our web agent can help you create, manage, and optimize your website for better performance and results.",
        slug: "web-agent",
        url: "/tools/agents/web-agent",
        icon: "/images/agent-icon/web-agent_line.png",
        badge: "featured",
    },
    {
        name: "SEO Agent",
        description: "Our SEO agent can help you optimize your website, content, and online presence for search engines.",
        slug: "seo-agent",
        url: "/tools/agents/seo-agent",
        icon: "/images/agent-icon/seo-agent_line.png",
        badge: "coming",
    },
    {
        name: "Marketing Agent",
        description: "Our marketing agent can help you create, manage, and optimize your marketing campaigns for better results.",
        slug: "marketing-agent",
        url: "/tools/agents/marketing-agent",
        icon: "/images/agent-icon/marketing.png",
        badge: "coming",
    },
    {
        name: "Content Agent",
        description: "Our content agent can help you create, manage, and optimize your content for better engagement and results.",
        slug: "content-agent",
        url: "/tools/agents/content-agent",
        icon: "/images/agent-icon/content.png",
        badge: "coming",
    },
    {
        name: "Social Media Agent",
        description: "Our social media agent can help you create, manage, and optimize your social media presence for better engagement and results.",
        slug: "social-media-agent",
        url: "/tools/agents/social-media-agent",
        icon: "/images/agent-icon/social_media.png",
        badge: "coming",
    },
    {
        name: "Customer Support Agent",
        description: "Our customer support agent trains on your website's data to provide instant support to your customers.",
        slug: "customer-support-agent",
        url: "/tools/agents/customer-support-agent",
        icon: "/images/agent-icon/customer_support.png",
        badge: "coming",
    },
    {
        name: "Sales Agent",
        description: "Our sales agent can help you generate more leads, close more deals, and grow your business faster.",
        slug: "sales-agent",
        url: "/tools/agents/sales-agent",
        icon: "/images/agent-icon/sales_agent.png",
        badge: "coming",
    },
    // {
    //     name: "Automated Content Auditing Agent",
    //     description: "Continuously audits and suggests improvements for existing content.",
    //     slug: "/tools/agents/automated-content-auditing",
    //     icon: "/images/agent-icon/content_auditing.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Cross-Cultural Communication Agent",
    //     description: "Ensures content is appropriate and effective across different cultural contexts.",
    //     slug: "/tools/agents/cross-cultural-communication",
    //     icon: "/images/agent-icon/cross_cultural.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Content Accessibility Compliance Agent",
    //     description: "Ensures all content meets the latest accessibility standards.",
    //     slug: "/tools/agents/accessibility-compliance",
    //     icon: "/images/agent-icon/accessibility.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Automated Content Localization Agent",
    //     description: "Manages the process of adapting content for different locales.",
    //     slug: "/tools/agents/content-localization",
    //     icon: "/images/agent-icon/localization.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Content Performance Optimization Agent",
    //     description: "Continuously analyzes and optimizes content performance across platforms.",
    //     slug: "/tools/agents/performance-optimization",
    //     icon: "/images/agent-icon/performance.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Automated Content Refreshing Agent",
    //     description: "Identifies and updates outdated content to maintain relevance.",
    //     slug: "/tools/agents/content-refreshing",
    //     icon: "/images/agent-icon/content_refresh.png",
    //     badge: "coming",
    // },
    // {
    //     name: "User Intent Mapping Agent",
    //     description: "Aligns content strategy with evolving user intents and search behaviors.",
    //     slug: "/tools/agents/user-intent-mapping",
    //     icon: "/images/agent-icon/user_intent.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Content Gap Analysis Agent",
    //     description: "Identifies content gaps in the market and suggests opportunities.",
    //     slug: "/tools/agents/content-gap-analysis",
    //     icon: "/images/agent-icon/gap_analysis.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Automated Content Curation Agent",
    //     description: "Curates and organizes third-party content for sharing or inspiration.",
    //     slug: "/tools/agents/content-curation",
    //     icon: "/images/agent-icon/curation.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Dynamic Pricing Content Agent",
    //     description: "Adjusts pricing-related content based on market conditions and user behavior.",
    //     slug: "/tools/agents/dynamic-pricing-content",
    //     icon: "/images/agent-icon/dynamic_pricing.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Omnichannel Content Orchestration Agent",
    //     description: "Manages content distribution across multiple channels for a cohesive user experience.",
    //     slug: "/tools/agents/omnichannel-orchestration",
    //     icon: "/images/agent-icon/omnichannel.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Predictive Content Personalization Agent",
    //     description: "Anticipates user needs and personalizes content in real-time.",
    //     slug: "/tools/agents/predictive-personalization",
    //     icon: "/images/agent-icon/personalization.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Content Compliance Monitoring Agent",
    //     description: "Ensures all content adheres to industry regulations and platform policies.",
    //     slug: "/tools/agents/compliance-monitoring",
    //     icon: "/images/agent-icon/compliance.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Automated Fact-Checking Agent",
    //     description: "Verifies factual claims in content against reliable sources.",
    //     slug: "/tools/agents/fact-checking",
    //     icon: "/images/agent-icon/fact_checking.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Content Attribution Agent",
    //     description: "Manages proper attribution and licensing for all content elements.",
    //     slug: "/tools/agents/content-attribution",
    //     icon: "/images/agent-icon/attribution.png",
    //     badge: "coming",
    // },
    // {
    //     name: "User-Generated Content Moderation Agent",
    //     description: "Monitors and moderates user-generated content in real-time.",
    //     slug: "/tools/agents/ugc-moderation",
    //     icon: "/images/agent-icon/ugc_moderation.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Content Archiving and Retrieval Agent",
    //     description: "Manages the archiving and easy retrieval of past content.",
    //     slug: "/tools/agents/archiving-retrieval",
    //     icon: "/images/agent-icon/archiving.png",
    //     badge: "coming",
    // },
    // {
    //     name: "SEO Impact Analysis Agent",
    //     description: "Evaluates the SEO impact of content changes and suggests optimizations.",
    //     slug: "/tools/agents/seo-impact-analysis",
    //     icon: "/images/agent-icon/seo_impact.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Content A/B Testing Agent",
    //     description: "Automatically conducts A/B tests on content variations and implements winners.",
    //     slug: "/tools/agents/ab-testing",
    //     icon: "/images/agent-icon/ab_testing.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Cross-Platform Analytics Agent",
    //     description: "Aggregates and analyzes content performance data across multiple platforms.",
    //     slug: "/tools/agents/cross-platform-analytics",
    //     icon: "/images/agent-icon/analytics.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Content Lifecycle Management Agent",
    //     description: "Oversees the entire lifecycle of content from ideation to archiving.",
    //     slug: "/tools/agents/lifecycle-management",
    //     icon: "/images/agent-icon/lifecycle.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Automated Content Tagging Agent",
    //     description: "Intelligently tags and categorizes content for improved organization and discoverability.",
    //     slug: "/tools/agents/content-tagging",
    //     icon: "/images/agent-icon/tagging.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Content Readability Optimization Agent",
    //     description: "Analyzes and improves the readability of content for target audiences.",
    //     slug: "/tools/agents/readability-optimization",
    //     icon: "/images/agent-icon/readability.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Competitive Content Analysis Agent",
    //     description: "Monitors and analyzes competitor content to inform content strategy.",
    //     slug: "/tools/agents/competitive-analysis",
    //     icon: "/images/agent-icon/competitive_analysis.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Content Translation Quality Assurance Agent",
    //     description: "Ensures the quality and accuracy of translated content across languages.",
    //     slug: "/tools/agents/translation-qa",
    //     icon: "/images/agent-icon/translation_qa.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Automated Content Brief Generator",
    //     description: "Creates detailed content briefs based on strategy, keywords, and competitive analysis.",
    //     slug: "/tools/agents/brief-generator",
    //     icon: "/images/agent-icon/brief_generator.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Content Consistency Checker Agent",
    //     description: "Ensures consistency in terminology, style, and messaging across all content.",
    //     slug: "/tools/agents/consistency-checker",
    //     icon: "/images/agent-icon/consistency_checker.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Automated Content Repurposing Agent",
    //     description: "Automatically repurposes existing content for different formats and platforms.",
    //     slug: "/tools/agents/content-repurposing",
    //     icon: "/images/agent-icon/repurposing.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Content Performance Forecasting Agent",
    //     description: "Predicts the potential performance of content before publication.",
    //     slug: "/tools/agents/performance-forecasting",
    //     icon: "/images/agent-icon/performance_forecast.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Automated Content Collaboration Agent",
    //     description: "Facilitates and manages collaboration between multiple content creators and stakeholders.",
    //     slug: "/tools/agents/content-collaboration",
    //     icon: "/images/agent-icon/collaboration.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Content Authenticity Verification Agent",
    //     description: "Verifies the authenticity and originality of content to prevent plagiarism.",
    //     slug: "/tools/agents/authenticity-verification",
    //     icon: "/images/agent-icon/authenticity.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Automated Content Distribution Agent",
    //     description: "Manages the timing and channels for content distribution based on audience behavior.",
    //     slug: "/tools/agents/content-distribution",
    //     icon: "/images/agent-icon/distribution.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Content Engagement Optimization Agent",
    //     description: "Analyzes user engagement patterns and suggests improvements to increase interaction.",
    //     slug: "/tools/agents/engagement-optimization",
    //     icon: "/images/agent-icon/engagement.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Automated Content Versioning Agent",
    //     description: "Manages different versions of content for various audiences or platforms.",
    //     slug: "/tools/agents/content-versioning",
    //     icon: "/images/agent-icon/versioning.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Content Sentiment Analysis Agent",
    //     description: "Monitors and responds to sentiment around published content.",
    //     slug: "/tools/agents/sentiment-analysis",
    //     icon: "/images/agent-icon/sentiment_analysis.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Omnichannel Content Orchestration Agent",
    //     description: "Manages content distribution across multiple channels for a cohesive user experience.",
    //     slug: "/tools/agents/omnichannel-orchestration",
    //     icon: "/images/agent-icon/omnichannel.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Predictive Content Personalization Agent",
    //     description: "Anticipates user needs and personalizes content in real-time.",
    //     slug: "/tools/agents/predictive-personalization",
    //     icon: "/images/agent-icon/personalization.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Content Compliance Monitoring Agent",
    //     description: "Ensures all content adheres to industry regulations and platform policies.",
    //     slug: "/tools/agents/compliance-monitoring",
    //     icon: "/images/agent-icon/compliance.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Automated Fact-Checking Agent",
    //     description: "Verifies factual claims in content against reliable sources.",
    //     slug: "/tools/agents/fact-checking",
    //     icon: "/images/agent-icon/fact_checking.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Content Attribution Agent",
    //     description: "Manages proper attribution and licensing for all content elements.",
    //     slug: "/tools/agents/content-attribution",
    //     icon: "/images/agent-icon/attribution.png",
    //     badge: "coming",
    // },
    // {
    //     name: "User-Generated Content Moderation Agent",
    //     description: "Monitors and moderates user-generated content in real-time.",
    //     slug: "/tools/agents/ugc-moderation",
    //     icon: "/images/agent-icon/ugc_moderation.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Content Archiving and Retrieval Agent",
    //     description: "Manages the archiving and easy retrieval of past content.",
    //     slug: "/tools/agents/archiving-retrieval",
    //     icon: "/images/agent-icon/archiving.png",
    //     badge: "coming",
    // },
    // {
    //     name: "SEO Impact Analysis Agent",
    //     description: "Evaluates the SEO impact of content changes and suggests optimizations.",
    //     slug: "/tools/agents/seo-impact-analysis",
    //     icon: "/images/agent-icon/seo_impact.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Content A/B Testing Agent",
    //     description: "Automatically conducts A/B tests on content variations and implements winners.",
    //     slug: "/tools/agents/ab-testing",
    //     icon: "/images/agent-icon/ab_testing.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Cross-Platform Analytics Agent",
    //     description: "Aggregates and analyzes content performance data across multiple platforms.",
    //     slug: "/tools/agents/cross-platform-analytics",
    //     icon: "/images/agent-icon/analytics.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Content Lifecycle Management Agent",
    //     description: "Oversees the entire lifecycle of content from ideation to archiving.",
    //     slug: "/tools/agents/lifecycle-management",
    //     icon: "/images/agent-icon/lifecycle.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Automated Content Tagging Agent",
    //     description: "Intelligently tags and categorizes content for improved organization and discoverability.",
    //     slug: "/tools/agents/content-tagging",
    //     icon: "/images/agent-icon/tagging.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Content Readability Optimization Agent",
    //     description: "Analyzes and improves the readability of content for target audiences.",
    //     slug: "/tools/agents/readability-optimization",
    //     icon: "/images/agent-icon/readability.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Competitive Content Analysis Agent",
    //     description: "Monitors and analyzes competitor content to inform content strategy.",
    //     slug: "/tools/agents/competitive-analysis",
    //     icon: "/images/agent-icon/competitive_analysis.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Content Translation Quality Assurance Agent",
    //     description: "Ensures the quality and accuracy of translated content across languages.",
    //     slug: "/tools/agents/translation-qa",
    //     icon: "/images/agent-icon/translation_qa.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Automated Content Brief Generator",
    //     description: "Creates detailed content briefs based on strategy, keywords, and competitive analysis.",
    //     slug: "/tools/agents/brief-generator",
    //     icon: "/images/agent-icon/brief_generator.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Content Consistency Checker Agent",
    //     description: "Ensures consistency in terminology, style, and messaging across all content.",
    //     slug: "/tools/agents/consistency-checker",
    //     icon: "/images/agent-icon/consistency_checker.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Automated Content Repurposing Agent",
    //     description: "Automatically repurposes existing content for different formats and platforms.",
    //     slug: "/tools/agents/content-repurposing",
    //     icon: "/images/agent-icon/repurposing.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Content Performance Forecasting Agent",
    //     description: "Predicts the potential performance of content before publication.",
    //     slug: "/tools/agents/performance-forecasting",
    //     icon: "/images/agent-icon/performance_forecast.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Automated Content Collaboration Agent",
    //     description: "Facilitates and manages collaboration between multiple content creators and stakeholders.",
    //     slug: "/tools/agents/content-collaboration",
    //     icon: "/images/agent-icon/collaboration.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Content Authenticity Verification Agent",
    //     description: "Verifies the authenticity and originality of content to prevent plagiarism.",
    //     slug: "/tools/agents/authenticity-verification",
    //     icon: "/images/agent-icon/authenticity.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Automated Content Distribution Agent",
    //     description: "Manages the timing and channels for content distribution based on audience behavior.",
    //     slug: "/tools/agents/content-distribution",
    //     icon: "/images/agent-icon/distribution.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Content Engagement Optimization Agent",
    //     description: "Analyzes user engagement patterns and suggests improvements to increase interaction.",
    //     slug: "/tools/agents/engagement-optimization",
    //     icon: "/images/agent-icon/engagement.png",
    //     badge: "coming",
    // },
    // {
    //     name: "Automated Content Versioning Agent",
    //     description: "Manages different versions of content for various audiences or platforms.",
    //     slug: "/tools/agents/content-versioning",
    //     icon: "/images/agent-icon/versioning.png",
    //     badge: "coming",
    // }
];

const prompts = []; 

export { generators, editors, assistants, agents, prompts };